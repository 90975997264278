/* eslint-disable no-unused-vars */
import React, { useState } from 'react'; // Import useState
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners'; // Import the spinner component

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State to manage loading

  const apiUrl = process.env.REACT_APP_API_URL;
  const onSubmit = async (data) => {
    setLoading(true); // Set loading to true when the form is submitted
    try {
      const response = await axios.post(`${apiUrl}/users/login`, data);
      const token = response.data.token;
      localStorage.setItem('token', token);
      toast.success('Login successful!');
      navigate('/home');
    } catch (error) {
      toast.error('Invalid email or password');
      console.error('Failed to login:', error);
    } finally {
      setLoading(false); // Set loading to false when the request is complete
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <ToastContainer />
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-12 w-auto"
          src="https://www.thezawajly.com/assets/Zawajly_Logo.png"
          alt="Zawajly"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="email" className="text-left block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                {...register('email', { required: 'Email is required' })}
                className="block w-full rounded-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
              />
              {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
            </div>
          </div>

          <div>
            <label htmlFor="password" className="text-left block text-sm font-medium leading-6 text-gray-900">
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                {...register('password', { required: 'Password is required' })}
                className="block w-full rounded-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
              />
              {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-pink-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
              disabled={loading} // Disable the button when loading
            >
              {loading ? <ClipLoader size={20} color={"#fff"} /> : 'Sign in'} {/* Show spinner when loading */}
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Don't have an account?{' '}
          <a href="/#/register" className="font-semibold leading-6 text-pink-600 hover:text-pink-500">
            Register
          </a>
        </p>

        <p className="mt-4 text-center text-sm text-gray-500">
          Forgot Password?{' '}
          <a href="/#/email-reset" className="font-semibold leading-6 text-pink-600 hover:text-pink-500">
            Reset
          </a>
        </p>
  
      </div>

    </div>
  );
};

export default Login;
