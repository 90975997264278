/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';


const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      await axios.post(`${apiUrl}/users/reset-password/${token}`, { newPassword });
      setSuccess('Password reset successful. Redirecting to login...');
      setTimeout(() => navigate('/#/login'), 3000);
    } catch (error) {
      console.error('Failed to reset password:', error);
      setError('Failed to reset password');
    }
  };

  return (
    <motion.div
    className="min-h-screen flex items-center justify-center"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    <ToastContainer />
    <motion.div
      className="max-w-md w-full bg-white p-6 rounded-md shadow-md"
      initial={{ scale: 0.8 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-2xl font-bold mb-6 text-center">Reset Password</h2>
      <form onSubmit={handleResetPassword}>
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
        >
          <label className="block text-sm font-medium text-left">New Password</label>
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </motion.div>
        <motion.div
          className="mt-4"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.5 }}
        >
          <label className="block text-sm font-medium text-left">Confirm Password</label>
          <input
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </motion.div>
        {error && (
          <motion.p
            className="mt-4 text-red-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7 }}
          >
            {error}
          </motion.p>
        )}
        {success && (
          <motion.p
            className="mt-4 text-green-600"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7 }}
          >
            {success}
          </motion.p>
        )}
        <motion.button
          type="submit"
          className="mt-6 w-full py-2 px-4 bg-pink-600 text-white font-semibold rounded-md shadow-sm hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Reset Password
        </motion.button>
      </form>
    </motion.div>
  </motion.div>
  );
};

export default ResetPassword;
