import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import "./App.css";
import Login from './pages/Login';
import Register from './pages/Register';
import VerifyEmail from './pages/VerifyEmail';
import Home from './pages/Home';
import Profile from './pages/Profile';
import AccountSettings from './pages/AccountSettings';
import ViewUserProfile from './pages/ViewUserProfile';
import EmailResetPage from './pages/EmailResetPage';
import ResetPassword from './pages/ResetPassword';
import RequestSent from './pages/RequestSent';
import PageNotFound from './pages/PageNotFound';

function App() {
  return (
    <Router>
      <div className="App">
        <main className="p-4">
          <Routes>
            <Route path="/" element={<Navigate to="/register" />} /> {/* Redirect root to /register */}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/home" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/account-settings" element={<AccountSettings />} />
            <Route path="/view-user/:id" element={<ViewUserProfile />} />
            <Route path="/email-reset" element={<EmailResetPage />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/request-sent" element={<RequestSent />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
