/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import Navbar from '../components/NavBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaUser, FaEnvelope, FaPhone, FaLock, FaUserShield, FaEdit, FaTrashAlt, FaSave, FaArrowLeft } from 'react-icons/fa';
import { motion } from 'framer-motion';


const AccountSettings = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const apiUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${apiUrl}/users/fetch-my-profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserInfo(response.data);
        reset(response.data);
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 404 || error.response.data.message === 'TokenExpiredError')) {
          localStorage.removeItem('token');
          window.location.href = '/#/login';
        } else {
          console.error('Failed to fetch profile:', error);
        }
      }
    };

    fetchProfile();
  }, [reset, apiUrl]);

  const onSubmit = async (data) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${apiUrl}/users/profile`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Account updated successfully');
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update account:', error);
      toast.error('Failed to update account');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${apiUrl}/users/profile`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Account deleted successfully');
      localStorage.removeItem('token');
      window.location.href = '/#/login';
    } catch (error) {
      console.error('Failed to delete account:', error);
      toast.error('Failed to delete account');
    }
  };

  if (!userInfo) {
    return (
<div role="status" className="flex justify-center items-center min-h-screen">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-2">
            <Navbar />
          </div>
          <div className="col-span-12 md:col-span-10 p-6 bg-white">
            <motion.div
              className="max-w-6xl mx-auto bg-white mt-8 p-6 rounded-lg shadow-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h1 className="text-3xl font-bold mb-6 text-white text-center bg-gradient-to-r from-pink-300 via-pink-400 to-pink-500 p-4 rounded-lg shadow-lg">
                Account Settings
              </h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="mb-6">
                      <label className="block text-md font-medium text-left text-pink-500 flex items-center">
                        <FaUser className="mr-2 text-sm" />Username
                      </label>
                      <input
                        className={`mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm ${!isEditing && 'bg-gray-100'}`}
                        type="text"
                        {...register('username')}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="mb-6">
                      <label className="block text-md font-medium text-left text-pink-500 flex items-center">
                        <FaEnvelope className="mr-2 text-sm" />Email Address
                      </label>
                      <input
                        className={`mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm ${!isEditing && 'bg-gray-100'}`}
                        type="email"
                        {...register('email')}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="mb-6">
                      <label className="block text-md font-medium text-left text-pink-500 flex items-center">
                        <FaPhone className="mr-2 text-sm" />Mobile Number
                      </label>
                      <input
                        className={`mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm ${!isEditing && 'bg-gray-100'}`}
                        type="text"
                        {...register('phoneNumber')}
                        disabled={!isEditing}
                      />
                    </div>
                    <div className="mt-10 text-left">
                      <NavLink
                        to="/email-reset"
                        className="text-pink-600 text-sm underline hover:text-pink-800 flex items-center"
                      >
                        <FaLock className="mr-1 text-sm" /> Reset Password
                      </NavLink>
                    </div>
                  </motion.div>
                  {userInfo.userType === 'female' && (
                    <motion.div
                      initial={{ opacity: 0, x: 20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="mb-6">
                        <label className="block text-md font-medium text-left text-pink-500 flex items-center">
                          <FaUserShield className="mr-2 text-sm" />Wali First Name
                        </label>
                        <input
                          className={`mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm ${!isEditing && 'bg-gray-100'}`}
                          type="text"
                          {...register('waliFirstName')}
                          disabled={!isEditing}
                        />
                      </div>
                      <div className="mb-6">
                        <label className="block text-md font-medium text-left text-pink-500 flex items-center">
                          <FaEnvelope className="mr-2 text-sm" />Wali Email
                        </label>
                        <input
                          className={`mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm ${!isEditing && 'bg-gray-100'}`}
                          type="email"
                          {...register('waliEmail')}
                          disabled={!isEditing}
                        />
                      </div>
                      <div className="mb-6">
                        <label className="block text-md font-medium text-left text-pink-500 flex items-center">
                          <FaPhone className="mr-2 text-sm" />Wali Phone Number
                        </label>
                        <input
                          className={`mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm ${!isEditing && 'bg-gray-100'}`}
                          type="text"
                          {...register('waliPhoneNumber')}
                          disabled={!isEditing}
                        />
                      </div>
                    </motion.div>
                  )}
                </div>
                {isEditing && (
                  <motion.div
                    className="mt-6 flex justify-between"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <button
                      type="submit"
                      className="py-2 px-4 bg-pink-600 text-white font-semibold rounded-md shadow-sm hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600 flex items-center"
                    >
                      <FaSave className="mr-2" /> Save Changes
                    </button>
                    <button
                      type="button"
                      className="py-2 px-4 bg-red-600 text-white font-semibold rounded-md shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 flex items-center"
                      onClick={handleDeleteAccount}
                    >
                      <FaTrashAlt className="mr-2" /> Delete Account
                    </button>
                  </motion.div>
                )}
              </form>
              {!isEditing && (
                <motion.div
                  className="mt-6 flex justify-end"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <button
                    type="button"
                    className="py-2 px-4 bg-gray-600 text-white font-semibold rounded-md shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 flex items-center"
                    onClick={() => setIsEditing(true)}
                  >
                    <FaEdit className="mr-2" /> Edit
                  </button>
                </motion.div>
              )}
            </motion.div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default AccountSettings;
