// src/pages/PageNotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
            <img
        src="https://images.unsplash.com/photo-1578328819058-b69f3a3b0f6b?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG"
        alt="Page not found"
        className="w-full max-w-md mb-4 rounded-lg shadow-md"
      />
      <h1 className="text-3xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="mb-4">Sorry, the page you are looking for does not exist.</p>
  
      <Link to="/#/login" className="py-2 px-4 bg-pink-600 text-white font-semibold rounded-md shadow-sm hover:bg-pink-500">
        Head Back
      </Link>
    </div>
  );
};

export default PageNotFound;
