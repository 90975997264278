import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../components/NavBar';
import Avatar from 'react-avatar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaUser, FaTag, FaInfoCircle, FaHeart, FaCalendarAlt, FaFlag, FaMapMarkerAlt, FaBook, FaChild, FaUsers, FaBalanceScale, FaWeight, FaArrowsAltV } from 'react-icons/fa';

const ViewUserProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;  
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${apiUrl}/users/view-user/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 404 || error.response.data.message === 'TokenExpiredError')) {
          navigate('/#/login');
        } else {
          setError('Failed to fetch user profile');
          setLoading(false);
        }
      }
    };

    fetchUserProfile();
  }, [id, navigate, apiUrl]);

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference).getUTCFullYear() - 1970;
    return age;
  };

  const handleSendRequest = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${apiUrl}/users/send-request`, { targetUserId: id }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      toast.success('Request sent successfully');
    } catch (error) {
      console.error('Failed to send request:', error);
      if (error.response && error.response.data === 'Your profile is incomplete. Please complete your profile before sending requests.') {
        toast.error('Your profile is incomplete. Please complete your profile before sending requests.');
      } else {
        toast.info('Request has been sent previously');
      }
    }
  };

  if (loading) {
    return (
      <div role="status">
        <svg aria-hidden="true" className="inline w-8 h-8 mt-64 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="grid grid-cols-12 min-h-screen">
      <div className="col-span-12 md:col-span-2">
        <Navbar />
      </div>
      <div className="col-span-12 md:col-span-10 p-6 bg-white">
        <div className="max-w-6xl mx-auto bg-white mt-8 rounded-xl shadow-lg p-8">
          <h1 className="text-4xl font-extrabold mb-6 text-white text-center bg-gradient-to-r from-pink-300 via-pink-400 to-pink-500 p-4 rounded-lg shadow-lg">
            {user.firstName}
          </h1>
          <div className="flex flex-col md:flex-row items-center mb-6">
            <Avatar
              name={user.username}
              src={user.chooseAvatar}
              size="100"
              round={true}
              className="mr-6"
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="mb-6">
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaUser className="mr-2 text-sm" />Username</p>
                <p className="mt-2 text-left text-gray-700">@{user.username}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaTag className="mr-2 text-sm" />Tagline</p>
                <p className="mt-2 text-left text-gray-700">{user.tagline}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaInfoCircle className="mr-2 text-sm" />About Me</p>
                <p className="mt-2 text-left text-gray-700">{user.aboutMe}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaHeart className="mr-2 text-sm" />What type of spouse am I looking for</p>
                <p className="mt-2 text-left text-gray-700">{user.spouseLookingFor}</p>
              </div>
            </div>
            <div className="mb-6">
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaCalendarAlt className="mr-2 text-sm" />Age</p>
                <p className="mt-2 text-left text-gray-700">{calculateAge(user.dateOfBirth)} years old</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaFlag className="mr-2 text-sm" />Ethnicity</p>
                <p className="mt-2 text-left text-gray-700">{user.ethnicity}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaMapMarkerAlt className="mr-2 text-sm" />Location</p>
                <p className="mt-2 text-left text-gray-700">{user.location}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaBook className="mr-2 text-sm" />Islamic Knowledge</p>
                <p className="mt-2 text-left text-gray-700">{user.islamicKnowledge}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaUsers className="mr-2 text-sm" />Islamic Background</p>
                <p className="mt-2 text-left text-gray-700">{user.background}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaBalanceScale className="mr-2 text-sm" />Marital Status</p>
                <p className="mt-2 text-left text-gray-700">{user.maritalStatus}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaChild className="mr-2 text-sm" />Prefer if spouse is working</p>
                <p className="mt-2 text-left text-gray-700">{user.spouseWorkingPreference ? 'Yes' : 'No'}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaChild className="mr-2 text-sm" />Any Children Currently</p>
                <p className="mt-2 text-left text-gray-700">{user.children}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaChild className="mr-2 text-sm" />How many kids do I want</p>
                <p className="mt-2 text-left text-gray-700">{user.desiredKids}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaArrowsAltV className="mr-2 text-sm" />Build</p>
                <p className="mt-2 text-left text-gray-700">{user.build}</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaWeight className="mr-2 text-sm" />Weight</p>
                <p className="mt-2 text-left text-gray-700">{user.weight} KG</p>
              </div>
              <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                <p className="flex items-center text-md font-medium text-left text-pink-500"><FaArrowsAltV className="mr-2 text-sm" />Height</p>
                <p className="mt-2 text-left text-gray-700">{user.height} CM</p>
              </div>
            </div>
          </div>
          <div className="mt-6 flex justify-between">
            <button
              className="py-2 px-4 bg-pink-600 mt-5 text-white font-semibold rounded-full shadow-lg hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600 transform transition-transform hover:scale-105"
              onClick={handleSendRequest}
            >
              Send Request
            </button>
            <button
              className="py-2 px-4 bg-gray-600 mt-5 text-white font-semibold rounded-full shadow-lg hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 transform transition-transform hover:scale-105"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ViewUserProfile;
