import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners'; // Import the spinner component
import zxcvbn from 'zxcvbn'; // Import the zxcvbn library

// List of country codes
const countryCodes = [
  { code: '+93', name: 'Afghanistan' },
  { code: '+355', name: 'Albania' },
  { code: '+213', name: 'Algeria' },
  { code: '+376', name: 'Andorra' },
  { code: '+244', name: 'Angola' },
  { code: '+54', name: 'Argentina' },
  { code: '+374', name: 'Armenia' },
  { code: '+61', name: 'Australia' },
  { code: '+43', name: 'Austria' },
  { code: '+994', name: 'Azerbaijan' },
  { code: '+973', name: 'Bahrain' },
  { code: '+880', name: 'Bangladesh' },
  { code: '+375', name: 'Belarus' },
  { code: '+32', name: 'Belgium' },
  { code: '+501', name: 'Belize' },
  { code: '+229', name: 'Benin' },
  { code: '+975', name: 'Bhutan' },
  { code: '+591', name: 'Bolivia' },
  { code: '+387', name: 'Bosnia and Herzegovina' },
  { code: '+267', name: 'Botswana' },
  { code: '+55', name: 'Brazil' },
  { code: '+359', name: 'Bulgaria' },
  { code: '+226', name: 'Burkina Faso' },
  { code: '+257', name: 'Burundi' },
  { code: '+855', name: 'Cambodia' },
  { code: '+237', name: 'Cameroon' },
  { code: '+1', name: 'Canada' },
  { code: '+238', name: 'Cape Verde' },
  { code: '+236', name: 'Central African Republic' },
  { code: '+235', name: 'Chad' },
  { code: '+56', name: 'Chile' },
  { code: '+86', name: 'China' },
  { code: '+57', name: 'Colombia' },
  { code: '+269', name: 'Comoros' },
  { code: '+242', name: 'Congo' },
  { code: '+243', name: 'Congo, Democratic Republic of the' },
  { code: '+506', name: 'Costa Rica' },
  { code: '+225', name: 'Cote d\'Ivoire' },
  { code: '+385', name: 'Croatia' },
  { code: '+53', name: 'Cuba' },
  { code: '+357', name: 'Cyprus' },
  { code: '+420', name: 'Czech Republic' },
  { code: '+45', name: 'Denmark' },
  { code: '+253', name: 'Djibouti' },
  { code: '+1', name: 'Dominica' },
  { code: '+1', name: 'Dominican Republic' },
  { code: '+593', name: 'Ecuador' },
  { code: '+20', name: 'Egypt' },
  { code: '+503', name: 'El Salvador' },
  { code: '+240', name: 'Equatorial Guinea' },
  { code: '+291', name: 'Eritrea' },
  { code: '+372', name: 'Estonia' },
  { code: '+251', name: 'Ethiopia' },
  { code: '+679', name: 'Fiji' },
  { code: '+358', name: 'Finland' },
  { code: '+33', name: 'France' },
  { code: '+241', name: 'Gabon' },
  { code: '+220', name: 'Gambia' },
  { code: '+995', name: 'Georgia' },
  { code: '+49', name: 'Germany' },
  { code: '+233', name: 'Ghana' },
  { code: '+30', name: 'Greece' },
  { code: '+299', name: 'Greenland' },
  { code: '+1', name: 'Grenada' },
  { code: '+502', name: 'Guatemala' },
  { code: '+224', name: 'Guinea' },
  { code: '+245', name: 'Guinea-Bissau' },
  { code: '+592', name: 'Guyana' },
  { code: '+509', name: 'Haiti' },
  { code: '+504', name: 'Honduras' },
  { code: '+852', name: 'Hong Kong' },
  { code: '+36', name: 'Hungary' },
  { code: '+354', name: 'Iceland' },
  { code: '+91', name: 'India' },
  { code: '+62', name: 'Indonesia' },
  { code: '+98', name: 'Iran' },
  { code: '+964', name: 'Iraq' },
  { code: '+353', name: 'Ireland' },
  { code: '+972', name: 'Israel' },
  { code: '+39', name: 'Italy' },
  { code: '+1', name: 'Jamaica' },
  { code: '+81', name: 'Japan' },
  { code: '+962', name: 'Jordan' },
  { code: '+7', name: 'Kazakhstan' },
  { code: '+254', name: 'Kenya' },
  { code: '+686', name: 'Kiribati' },
  { code: '+965', name: 'Kuwait' },
  { code: '+996', name: 'Kyrgyzstan' },
  { code: '+856', name: 'Laos' },
  { code: '+371', name: 'Latvia' },
  { code: '+961', name: 'Lebanon' },
  { code: '+266', name: 'Lesotho' },
  { code: '+231', name: 'Liberia' },
  { code: '+218', name: 'Libya' },
  { code: '+423', name: 'Liechtenstein' },
  { code: '+370', name: 'Lithuania' },
  { code: '+352', name: 'Luxembourg' },
  { code: '+853', name: 'Macau' },
  { code: '+389', name: 'Macedonia' },
  { code: '+261', name: 'Madagascar' },
  { code: '+265', name: 'Malawi' },
  { code: '+60', name: 'Malaysia' },
  { code: '+960', name: 'Maldives' },
  { code: '+223', name: 'Mali' },
  { code: '+356', name: 'Malta' },
  { code: '+692', name: 'Marshall Islands' },
  { code: '+222', name: 'Mauritania' },
  { code: '+230', name: 'Mauritius' },
  { code: '+52', name: 'Mexico' },
  { code: '+691', name: 'Micronesia' },
  { code: '+373', name: 'Moldova' },
  { code: '+377', name: 'Monaco' },
  { code: '+976', name: 'Mongolia' },
  { code: '+382', name: 'Montenegro' },
  { code: '+212', name: 'Morocco' },
  { code: '+258', name: 'Mozambique' },
  { code: '+95', name: 'Myanmar' },
  { code: '+264', name: 'Namibia' },
  { code: '+674', name: 'Nauru' },
  { code: '+977', name: 'Nepal' },
  { code: '+31', name: 'Netherlands' },
  { code: '+64', name: 'New Zealand' },
  { code: '+505', name: 'Nicaragua' },
  { code: '+227', name: 'Niger' },
  { code: '+234', name: 'Nigeria' },
  { code: '+47', name: 'Norway' },
  { code: '+968', name: 'Oman' },
  { code: '+92', name: 'Pakistan' },
  { code: '+680', name: 'Palau' },
  { code: '+970', name: 'Palestine' },
  { code: '+507', name: 'Panama' },
  { code: '+675', name: 'Papua New Guinea' },
  { code: '+595', name: 'Paraguay' },
  { code: '+51', name: 'Peru' },
  { code: '+63', name: 'Philippines' },
  { code: '+48', name: 'Poland' },
  { code: '+351', name: 'Portugal' },
  { code: '+974', name: 'Qatar' },
  { code: '+242', name: 'Republic of the Congo' },
  { code: '+40', name: 'Romania' },
  { code: '+7', name: 'Russia' },
  { code: '+250', name: 'Rwanda' },
  { code: '+685', name: 'Samoa' },
  { code: '+378', name: 'San Marino' },
  { code: '+239', name: 'Sao Tome and Principe' },
  { code: '+966', name: 'Saudi Arabia' },
  { code: '+221', name: 'Senegal' },
  { code: '+381', name: 'Serbia' },
  { code: '+248', name: 'Seychelles' },
  { code: '+232', name: 'Sierra Leone' },
  { code: '+65', name: 'Singapore' },
  { code: '+421', name: 'Slovakia' },
  { code: '+386', name: 'Slovenia' },
  { code: '+677', name: 'Solomon Islands' },
  { code: '+252', name: 'Somalia' },
  { code: '+27', name: 'South Africa' },
  { code: '+82', name: 'South Korea' },
  { code: '+211', name: 'South Sudan' },
  { code: '+34', name: 'Spain' },
  { code: '+94', name: 'Sri Lanka' },
  { code: '+249', name: 'Sudan' },
  { code: '+597', name: 'Suriname' },
  { code: '+268', name: 'Swaziland' },
  { code: '+46', name: 'Sweden' },
  { code: '+41', name: 'Switzerland' },
  { code: '+963', name: 'Syria' },
  { code: '+886', name: 'Taiwan' },
  { code: '+992', name: 'Tajikistan' },
  { code: '+255', name: 'Tanzania' },
  { code: '+66', name: 'Thailand' },
  { code: '+228', name: 'Togo' },
  { code: '+676', name: 'Tonga' },
  { code: '+1', name: 'Trinidad and Tobago' },
  { code: '+216', name: 'Tunisia' },
  { code: '+90', name: 'Turkey' },
  { code: '+993', name: 'Turkmenistan' },
  { code: '+688', name: 'Tuvalu' },
  { code: '+256', name: 'Uganda' },
  { code: '+380', name: 'Ukraine' },
  { code: '+971', name: 'United Arab Emirates' },
  { code: '+44', name: 'United Kingdom' },
  { code: '+1', name: 'United States' },
  { code: '+598', name: 'Uruguay' },
  { code: '+998', name: 'Uzbekistan' },
  { code: '+678', name: 'Vanuatu' },
  { code: '+58', name: 'Venezuela' },
  { code: '+84', name: 'Vietnam' },
  { code: '+967', name: 'Yemen' },
  { code: '+260', name: 'Zambia' },
  { code: '+263', name: 'Zimbabwe' }
];

const Register = () => {
  const [formData, setFormData] = useState({
    userType: 'male', // Default to 'male'
    username: '',
    email: '',
    password: '',
    phoneNumber: '',
    countryCode: '+1', // Default country code
    waliFirstName: '',
    waliEmail: '',
    waliPhoneNumber: '',
    waliCountryCode: '+1', // Default Wali country code
  });
  const [loading, setLoading] = useState(false); // State to manage loading
  const [passwordStrength, setPasswordStrength] = useState(0); // State to manage password strength

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'password') {
      const result = zxcvbn(value);
      setPasswordStrength(result.score);
    }
  };

  const getPasswordStrengthMessage = (score) => {
    switch (score) {
      case 0:
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return '';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordStrength < 3) {
      toast.error('Password must be strong.', {
        className: 'toast-error'
      });
      return;
    }

    if (formData.userType === 'female') {
      if (formData.waliEmail === formData.email) {
        toast.error('Wali\'s email cannot be the same as user\'s email.', {
          className: 'toast-error'
        });
        return;
      }
      if (formData.waliCountryCode + formData.waliPhoneNumber === formData.countryCode + formData.phoneNumber) {
        toast.error('Wali\'s phone number cannot be the same as user\'s phone number.', {
          className: 'toast-error'
        });
        return;
      }
    }

    const formattedFormData = {
      ...formData,
      phoneNumber: formData.countryCode + formData.phoneNumber,
      waliPhoneNumber: formData.waliCountryCode + formData.waliPhoneNumber,
    };
    setLoading(true); // Set loading to true when the form is submitted
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios.post(`${apiUrl}/users/register`, formattedFormData);
      toast.success('Registration successful! Please check your email to verify your account.', {
        className: 'toast-success'
      });
      navigate('/verify-email'); // Redirect to verify email page after successful registration
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data || 'There was an error registering the user!';
        if (error.response.status === 400 && error.response.data === 'Email already exists. Please use a different email.') {
          toast.error('Email already exists. Please use a different email.', {
            className: 'toast-error'
          });
        } else {
          toast.error(errorMessage, {
            className: 'toast-error'
          });
        }
      } else if (error.request) {
        toast.error('No response received from the server. Please try again later.', {
          className: 'toast-error'
        });
      } else {
        toast.error('There was an error setting up the request.', {
          className: 'toast-error'
        });
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <ToastContainer />
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-12 w-auto"
          src="https://www.thezawajly.com/assets/Zawajly_Logo.png"
          alt="Zawajly"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Register for an account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="userType" className="text-left block text-sm font-medium leading-6 text-gray-900">
              User Type
            </label>
            <div className="mt-2">
              <select
                id="userType"
                name="userType"
                required
                value={formData.userType}
                onChange={handleChange}
                className="block w-full rounded-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>

          <div>
            <label htmlFor="username" className="text-left block text-sm font-medium leading-6 text-gray-900">
              Username
            </label>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                type="text"
                required
                value={formData.username}
                onChange={handleChange}
                className="block w-full rounded-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="text-left block text-sm font-medium leading-6 text-gray-900">
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={formData.email}
                onChange={handleChange}
                className="block w-full rounded-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="text-left block text-sm font-medium leading-6 text-gray-900">
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                value={formData.password}
                onChange={handleChange}
                className="block w-full rounded-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
              />
                    <div className="mt-1">
  <div className="flex items-center space-x-2">
    <div className="flex-1 h-2 rounded-full overflow-hidden bg-gray-200">
      <div
        className={`h-full transition-all duration-300 ${
          passwordStrength === 0
            ? 'w-1/6 bg-red-500'
            : passwordStrength === 1
            ? 'w-1/3 bg-red-400'
            : passwordStrength === 2
            ? 'w-1/2 bg-yellow-400'
            : passwordStrength === 3
            ? 'w-4/5 bg-green-400'
            : 'w-full bg-green-500'
        }`}
      ></div>
    </div>
    <p className={`text-sm font-medium ${passwordStrength < 2 ? 'text-red-500' : passwordStrength === 2 ? 'text-yellow-500' : 'text-green-500'}`}>
      {getPasswordStrengthMessage(passwordStrength)}
    </p>
  </div>
</div>
            </div>
          </div>

          <div>
            <label htmlFor="phoneNumber" className="text-left block text-sm font-medium leading-6 text-gray-900">
              Phone Number
            </label>
            <div className="flex mt-2">
              <select
                id="countryCode"
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                className="block w-24 rounded-l-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>
              <input
                id="phoneNumber"
                name="phoneNumber"
                type="text"
                required
                value={formData.phoneNumber}
                onChange={handleChange}
                className="block flex-grow rounded-r-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          {formData.userType === 'female' && (
            <>
              <div>
                <label htmlFor="waliFirstName" className="text-left block text-sm font-medium leading-6 text-gray-900">
                  Wali's First Name
                </label>
                <div className="mt-2">
                  <input
                    id="waliFirstName"
                    name="waliFirstName"
                    type="text"
                    required
                    value={formData.waliFirstName}
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="waliEmail" className="text-left block text-sm font-medium leading-6 text-gray-900">
                  Wali's Email
                </label>
                <div className="mt-2">
                  <input
                    id="waliEmail"
                    name="waliEmail"
                    type="email"
                    required
                    value={formData.waliEmail}
                    onChange={handleChange}
                    className="block w-full rounded-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="waliPhoneNumber" className="text-left block text-sm font-medium leading-6 text-gray-900">
                  Wali's Phone Number
                </label>
                <div className="flex mt-2">
                  <select
                    id="waliCountryCode"
                    name="waliCountryCode"
                    value={formData.waliCountryCode}
                    onChange={handleChange}
                    className="block w-24 rounded-l-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                  >
                    {countryCodes.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name} ({country.code})
                      </option>
                    ))}
                  </select>
                  <input
                    id="waliPhoneNumber"
                    name="waliPhoneNumber"
                    type="text"
                    required
                    value={formData.waliPhoneNumber}
                    onChange={handleChange}
                    className="block flex-grow rounded-r-md border-gray-300 py-1.5 px-3 text-gray-900 shadow-sm focus:border-pink-600 focus:ring-2 focus:ring-inset focus:ring-pink-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </>
          )}

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-pink-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
              disabled={loading} // Disable the button when loading
            >
              {loading ? <ClipLoader size={20} color={"#fff"} /> : 'Register'} {/* Show spinner when loading */}
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already have an account?{' '}
          <a href="/#/login" className="font-semibold leading-6 text-pink-600 hover:text-pink-500">
            Sign in
          </a>
        </p>
      </div>
    </div>
  );
};

export default Register;
