/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Navbar from '../components/NavBar';
import Avatar from 'react-avatar';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaUser, FaTag, FaInfoCircle, FaHeart, FaCalendarAlt, FaFlag, FaMapMarkerAlt, FaBook, FaChild, FaUsers, FaBalanceScale, FaWeight, FaArrowsAltV } from 'react-icons/fa';

const Profile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const aboutMe = watch('aboutMe', '');
  const spouseLookingFor = watch('spouseLookingFor', '');
  const tagline = watch('tagline', '');
  const location = watch('location', '');
  const ethnicity = watch('ethnicity', '');
  const dateOfBirth = watch('dateOfBirth', '');

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/#/login');
        return;
      }

      try {
        const response = await axios.get(`${apiUrl}/users/fetch-my-profile`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserInfo(response.data);
        reset(response.data); // Populate the form with fetched data
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 404 || error.response.data.message === 'TokenExpiredError')
        ) {
          navigate('/#/login');
        } else {
          console.error('Failed to fetch profile:', error);
        }
      }
    };

    fetchProfile();
  }, [reset, navigate, apiUrl]);

  const onSubmit = async (data) => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/#/login');
      return;
    }

    const age = calculateAge(data.dateOfBirth);
    if (age < 18) {
      toast.error('You must be at least 18 years old.');
      return;
    }

    if (aboutMe.length > 240 || spouseLookingFor.length > 120 || tagline.length > 30 || location.length > 50 || ethnicity.length > 20) {
      toast.error('Character limit exceeded in one or more fields.');
      return;
    }

    try {
      await axios.put(`${apiUrl}/users/profile`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Profile Updated successfully');
    } catch (error) {
      console.error('Failed to update profile:', error);
      toast.error('Failed to update profile');
    }
  };

  const handleAvatarChange = (avatar) => {
    setUserInfo((prev) => ({ ...prev, chooseAvatar: avatar }));
    setValue('chooseAvatar', avatar); // Update the form value
    setIsOpen(false); // Close the modal after selecting an avatar
  };

  const calculateAge = (dateOfBirth) => {
    const birthDate = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  };

  const avatars = [
    "/assets/avatar_1.png",
    "/assets/avatar_2.png",
    "/assets/avatar_3.png",
    "/assets/avatar_4.png",
    "/assets/avatar_5.png",
    "/assets/avatar_6.png",
  ];

  if (!userInfo) {
    return <div role="status">
    <svg aria-hidden="true" className="inline w-8 h-8 mt-64 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span className="sr-only">Loading...</span>
</div>;;
  }

  return (
    <div className="grid grid-cols-12 min-h-screen">
      <div className="col-span-12 md:col-span-2">
        <Navbar />
      </div>
      <div className="col-span-12 md:col-span-10 p-6 bg-white">
        <div className="max-w-6xl mx-auto bg-white mt-8">
          <h1 className="text-3xl font-bold mb-6 text-white text-center bg-gradient-to-r from-pink-300 via-pink-400 to-pink-500 p-4 rounded-lg shadow-lg">Profile</h1>
          <div className="flex flex-col md:flex-row items-center mb-6">
            <img
              className="w-24 h-24 rounded-full mr-6"
              src={userInfo.chooseAvatar || "/path/to/avatar.jpg"}
              alt="User Avatar"
            />
            <button className="text-pink-600 mr-5 mt-3" onClick={() => setIsOpen(true)}>Change Avatar</button>
          </div>
          {isEditMode ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Left Column */}
                <div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaTag className="mr-2 text-sm" />Tagline</label>
                    <input
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      type="text"
                      maxLength="30"
                      {...register('tagline')}
                    />
                    <p className="text-right text-xs text-gray-500">{tagline.length}/30</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaInfoCircle className="mr-2 text-sm" />About Me</label>
                    <textarea
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      rows="3"
                      maxLength="240"
                      {...register('aboutMe')}
                    />
                    <p className="text-right text-xs text-gray-500">{aboutMe.length}/240</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaHeart className="mr-2 text-sm" />What type of spouse am I looking for</label>
                    <textarea
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      rows="3"
                      maxLength="120"
                      {...register('spouseLookingFor')}
                    />
                    <p className="text-right text-xs text-gray-500">{spouseLookingFor.length}/120</p>
                  </div>
                </div>
                {/* Right Column */}
                <div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaUser className="mr-2 text-sm" />First Name</label>
                    <input
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      type="text"
                      {...register('firstName')}
                    />
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaUser className="mr-2 text-sm" />Last Name</label>
                    <input
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      type="text"
                      {...register('lastName')}
                    />
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaCalendarAlt className="mr-2 text-sm" />Date of Birth</label>
                    <input
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      type="date"
                      {...register('dateOfBirth')}
                    />
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaFlag className="mr-2 text-sm" />Ethnicity</label>
                    <input
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      type="text"
                      maxLength="20"
                      {...register('ethnicity', {
                        validate: (value) => !/[ ,]/.test(value) || 'Ethnicity cannot contain spaces or commas'
                      })}
                    />
                    <p className="text-right text-xs text-gray-500">{ethnicity.length}/20</p>
                    {errors.ethnicity && <p className="text-red-500 text-xs mt-1">{errors.ethnicity.message}</p>}
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaMapMarkerAlt className="mr-2 text-sm" />Current Location</label>
                    <input
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      type="text"
                      maxLength="50"
                      {...register('location')}
                    />
                    <p className="text-right text-xs text-gray-500">{location.length}/50</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaBook className="mr-2 text-sm" />Islamic Knowledge</label>
                    <select
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      {...register('islamicKnowledge')}
                    >
                      <option value="Basic">Basic</option>
                      <option value="Very Good">Very Good</option>
                      <option value="Deep">Deep</option>
                    </select>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaUsers className="mr-2 text-sm" />Islamic Background</label>
                    <select
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      {...register('background')}
                    >
                      <option value="born muslim">Born Muslim</option>
                      <option value="revert">Revert</option>
                    </select>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaBalanceScale className="mr-2 text-sm" />Marital Status</label>
                    <select
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      {...register('maritalStatus')}
                    >
                      <option value="Single">Single</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                      <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaChild className="mr-2 text-sm" />Prefer if spouse is working</label>
                    <select
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      {...register('spouseWorkingPreference')}
                    >
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaChild className="mr-2 text-sm" />Any Children Currently</label>
                    <input
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      type="number"
                      {...register('children')}
                    />
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaChild className="mr-2 text-sm" />How many kids do I want</label>
                    <input
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      type="number"
                      {...register('desiredKids')}
                    />
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaArrowsAltV className="mr-2 text-sm" />Build</label>
                    <select
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      {...register('build')}
                    >
                      <option value="Slender">Slender</option>
                      <option value="Athletic">Athletic</option>
                      <option value="Average">Average</option>
                      <option value="Curvy">Curvy</option>
                      <option value="Full-Figured">Full-Figured</option>
                      <option value="Muscular">Muscular</option>
                      <option value="Petite">Petite</option>
                      <option value="Stocky">Stocky</option>
                    </select>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaWeight className="mr-2 text-sm" />Weight</label>
                    <select
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      {...register('weight')}
                    >
                      {Array.from({ length: 141 }, (_, i) => (
                        <option key={i} value={i + 40}>{i + 40} KG</option>
                      ))}
                    </select>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <label className="block text-sm font-medium text-left text-pink-500 flex items-center"><FaArrowsAltV className="mr-2 text-sm" />Height</label>
                    <select
                      className="mt-1 block w-full rounded-md border-pink-300 shadow-sm p-3 focus:border-pink-600 focus:ring-pink-600 sm:text-sm"
                      {...register('height')}
                    >
                      {Array.from({ length: 151 }, (_, i) => (
                        <option key={i} value={i + 100}>{i + 100} CM</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  className="py-2 px-4 bg-gray-800 text-white font-semibold rounded-md shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
                  onClick={() => setIsEditMode(false)}
                >
                  View
                </button>
                <button
                  type="submit"
                  className="py-2 px-4 bg-pink-600 text-white font-semibold rounded-md shadow-sm hover:bg-pink-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-600"
                >
                  Save Changes
                </button>
              </div>
            </form>
          ) : (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Left Column */}
                <div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaTag className="mr-2 text-sm" />Tagline</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.tagline}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaInfoCircle className="mr-2 text-sm" />About Me</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.aboutMe}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaHeart className="mr-2 text-sm" />What type of spouse am I looking for</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.spouseLookingFor}</p>
                  </div>
                </div>
                {/* Right Column */}
                <div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaUser className="mr-2 text-sm" />First Name</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.firstName}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaUser className="mr-2 text-sm" />Last Name</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.lastName}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaCalendarAlt className="mr-2 text-sm" />Age</p>
                    <p className="mt-2 text-left text-gray-700">{calculateAge(userInfo.dateOfBirth)} years old</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaFlag className="mr-2 text-sm" />Ethnicity</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.ethnicity}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaMapMarkerAlt className="mr-2 text-sm" />Current Location</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.location}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaBook className="mr-2 text-sm" />Islamic Knowledge</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.islamicKnowledge}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaUsers className="mr-2 text-sm" />Islamic Background</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.background}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaBalanceScale className="mr-2 text-sm" />Marital Status</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.maritalStatus}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaChild className="mr-2 text-sm" />Prefer if spouse is working</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.spouseWorkingPreference ? 'Yes' : 'No'}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaChild className="mr-2 text-sm" />Any Children Currently</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.children}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaChild className="mr-2 text-sm" />How many kids do I want</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.desiredKids}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaArrowsAltV className="mr-2 text-sm" />Build</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.build}</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaWeight className="mr-2 text-sm" />Weight</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.weight} KG</p>
                  </div>
                  <div className="bg-pink-50 p-4 rounded-lg shadow-md mb-6">
                    <p className="flex items-center text-md font-medium text-left text-pink-500"><FaArrowsAltV className="mr-2 text-sm" />Height</p>
                    <p className="mt-2 text-left text-gray-700">{userInfo.height} CM</p>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  className="py-2 px-4 bg-gray-800 text-white font-semibold rounded-md shadow-sm hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
                  onClick={() => setIsEditMode(true)}
                >
                  Edit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)} center classNames={{ modal: 'customModal' }}>
        <h2 className="text-xl font-bold mb-4 mr-10">Select Avatar</h2>
        <div className="flex gap-4 flex-wrap mt-8">
          {avatars.map((avatar, index) => (
            <div key={index} className="cursor-pointer" onClick={() => handleAvatarChange(avatar)}>
              <Avatar src={avatar} size="100" round />
            </div>
          ))}
        </div>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default Profile;
