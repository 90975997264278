import React from 'react';

const VerifyEmail = () => {
  return (
    <div className="flex min-h-screen flex-1 flex-col items-center justify-center  px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto"
          src="https://www.thezawajly.com/assets/Zawajly_Logo.png"
          alt="Zawajly"
        />
        <h2 className="mt-6 text-center text-2xl font-bold leading-9 text-gray-900">
          Please Verify Your Email
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          A verification link has been sent to your email address. Please click on the link to verify your email.
        </p>
      </div>
    </div>
  );
};

export default VerifyEmail;
